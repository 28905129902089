import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ReactTypingEffect from "react-typing-effect"
import { Images } from "~data"
import { Link } from "~components"
import { Button } from "~styled"
import ImageGroup from "./Components/ImageGroup"
import Hero from "./style"

export default function HeroSection() {
  return (
    <Hero className="position-relative bg-default">
      <Container>
        <Row>
          {/* Welcome content Area */}
          <Col
            className="col-xl-6 col-lg-7 col-md-8 col-xs-11 order-2 order-lg-1"
            xs="12"
          >
            <Hero.Content>
              <Hero.Title as="h1">
                Unlock Your Potential,{" "}
                <span className="highlight-text d-inline-block text-primary">
                  One Step at a Time
                </span>
              </Hero.Title>
              <Hero.Text>
                Monsai is your AI growth companion, guiding you through
                self-discovery, goal setting, and habit building, so you can
                achieve lasting personal growth, one step at a time
              </Hero.Text>
              {/* Newsletter */}
              <Row>
                <Col xs="12" className="col-xxl-10">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.monsai"
                    target={"_blank"}
                  >
                    <Hero.Image src={Images.GooglePlay} alt="google play" />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/monsai-build-a-life-you-love/id6475244334"
                    target={"_blank"}
                  >
                    <Hero.Image src={Images.AppStore} alt="app store" />
                  </a>
                </Col>
              </Row>
            </Hero.Content>
          </Col>
          {/*/ .Welcome Content Area */}
          {/*Welcome Image Area */}
          <Col
            xs={12}
            className="col-xl-6 col-lg-5 col-md-10 order-1 order-lg-2 position-static"
          >
            <ImageGroup />
          </Col>
          {/*/ .Welcome Image Area */}
        </Row>
      </Container>
    </Hero>
  )
}
