import React from "react"
import { Helmet } from "react-helmet"
import { PageWrapper } from "~components/Core"
import TermsSection from "~sections/utility/Legal/TermsSection"
import FooterSection from "~sections/utility/Footer"

export default function Terms() {
  const header = {
    headerClasses: "position-relative",
  }
  return (
    <PageWrapper innerPage={true} headerConfig={header}>
      <Helmet>
        <title>Terms of Use - Monsai</title>
        <meta
          name="description"
          content="Read the terms of use that govern the use of Monsai's services and platform."
        />
        <meta property="og:title" content="Terms of Use - Monsai" />
        <meta
          property="og:description"
          content="Read the terms of use that govern the use of Monsai's services and platform."
        />
        <meta property="og:url" content="https://monsaiapp.com/terms/" />
        <meta property="twitter:card" content="summary" />
        <meta name="twitter:title" content="Terms of Use - Monsai" />
        <meta
          name="twitter:description"
          content="Read the terms of use that govern the use of Monsai's services and platform."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Terms of Use",
              "url": "https://monsaiapp.com/terms/",
              "description": "Read the terms of use that govern the use of Monsai's services and platform."
            }
          `}
        </script>
      </Helmet>

      <TermsSection />
      <FooterSection />
    </PageWrapper>
  )
}
