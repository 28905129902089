import React from "react"
import { Helmet } from "react-helmet"
import { PageWrapper } from "~components/Core"
import SearchSection from "~sections/utility/Faq/SearchSection"
import FaqSection from "~sections/utility/Faq/FaqSection"
import FooterSection from "~sections/utility/Footer"
import faqData from "~data/FaqData"

const sanitizeAnswer = answer => {
  return answer
    .replace(/<span>/g, "") // Remove <span> tags
    .replace(/<\/span>/g, "") // Remove </span> tags
    .replace(/\n/g, "") // Remove newlines
    .replace(/\s{2,}/g, " ") // Collapse multiple spaces
    .trim() // Trim leading and trailing spaces
}

const generateFAQSchema = faqGroups => {
  const mainEntity = []
  faqGroups.forEach(group => {
    group.items.forEach(item => {
      mainEntity.push({
        "@type": "Question",
        name: item.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: sanitizeAnswer(item.answer),
        },
      })
    })
  })
  return mainEntity
}

const faqSchema = {
  "@context": "http://schema.org",
  "@type": "FAQPage",
  name: "FAQs",
  url: "https://monsaiapp.com/faqs/",
  description:
    "Find answers to frequently asked questions about Monsai's app, features, and services.",
  mainEntity: generateFAQSchema(faqData),
}

export default function FaqPage() {
  const header = {
    headerClasses: "position-relative",
  }
  return (
    <PageWrapper innerPage={true} headerConfig={header}>
      <Helmet>
        <title>FAQs - Monsai - Your Personal Growth Companion</title>
        <meta
          name="description"
          content="Find answers to frequently asked questions about Monsai's app, features, and services."
        />
        <meta property="og:title" content="FAQs - Monsai" />
        <meta
          property="og:description"
          content="Find answers to frequently asked questions about Monsai's app, features, and services."
        />
        <meta property="og:url" content="https://monsaiapp.com/faqs/" />
        <meta property="twitter:card" content="summary" />
        <meta name="twitter:title" content="FAQs - Monsai" />
        <meta
          name="twitter:description"
          content="Find answers to frequently asked questions about Monsai's app, features, and services."
        />
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>

      <SearchSection />
      <FaqSection />
      <FooterSection />
    </PageWrapper>
  )
}
