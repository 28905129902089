import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { BlogRegular } from "~sections/Blog"
import FooterSection from "~sections/utility/Footer"
import { PageWrapper } from "~components/Core"

export default function blog({ data }) {
  return (
    <PageWrapper innerPage={true}>
      <Helmet>
        <title>Blog - Monsai</title>
        <meta
          name="description"
          content="Explore Monsai's blog for insights on personal growth, habits, mindfulness, and more."
        />
        <meta property="og:title" content="Blog - Monsai" />
        <meta
          property="og:description"
          content="Explore Monsai's blog for insights on personal growth, habits, mindfulness, and more."
        />
        <meta property="og:url" content="https://monsaiapp.com/blog/" />

        <meta name="twitter:title" content="Blog - Monsai" />
        <meta
          name="twitter:description"
          content="Explore Monsai's blog for insights on personal growth, habits, mindfulness, and more."
        />

        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Blog",
            "url": "https://monsaiapp.com/blog/",
            "name": "Monsai Blog",
            "description": "Explore Monsai's blog for insights on personal growth, habits, mindfulness, and more."
          }
        `}
        </script>
      </Helmet>

      <BlogRegular data={data} />
      <FooterSection />
    </PageWrapper>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          category
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
      }
    }
  }
`
