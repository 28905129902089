import React from "react"
import { Helmet } from "react-helmet"
import { PageWrapper } from "~components/Core"
import PrivacySection from "~sections/utility/Legal/PrivacySection"
import FooterSection from "~sections/utility/Footer"

export default function Privacy() {
  const header = {
    headerClasses: "position-relative",
  }
  return (
    <PageWrapper innerPage={true} headerConfig={header}>
      <Helmet>
        <title>Privacy Policy - Monsai</title>
        <meta
          name="description"
          content="Learn how Monsai collects, uses, and protects your personal data in our Privacy Policy."
        />
        <meta property="og:title" content="Privacy Policy - Monsai" />
        <meta
          property="og:description"
          content="Learn how Monsai collects, uses, and protects your personal data in our Privacy Policy."
        />
        <meta property="og:url" content="https://monsaiapp.com/privacy/" />
        <meta property="twitter:card" content="summary" />
        <meta name="twitter:title" content="Privacy Policy - Monsai" />
        <meta
          name="twitter:description"
          content="Learn how Monsai collects, uses, and protects your personal data in our Privacy Policy."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Privacy Policy",
              "url": "https://monsaiapp.com/privacy/",
              "description": "Learn how Monsai collects, uses, and protects your personal data in our Privacy Policy."
            }
          `}
        </script>
      </Helmet>

      <PrivacySection />
      <FooterSection />
    </PageWrapper>
  )
}
