import React from "react"
import { Helmet } from "react-helmet"
import { Error } from "~sections/utility"
import { PageWrapper } from "~components/Core"
import FooterSection from "~sections/utility/Footer"

export default function errorPage() {
  return (
    <PageWrapper innerPage={true}>
      <Helmet>
        <title>404 - Page Not Found | Monsai</title>
        <meta
          name="description"
          content="The page you are looking for could not be found. Explore Monsai for personal growth, wellness, and productivity."
        />
        <meta property="og:title" content="404 - Page Not Found | Monsai" />
        <meta
          property="og:description"
          content="The page you are looking for could not be found. Explore Monsai for personal growth, wellness, and productivity."
        />
        <meta property="og:url" content="https://monsaiapp.com/404/" />
        <meta property="twitter:card" content="summary" />
        <meta name="twitter:title" content="404 - Page Not Found | Monsai" />
        <meta
          name="twitter:description"
          content="The page you are looking for could not be found. Explore Monsai for personal growth, wellness, and productivity."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "404 - Page Not Found",
              "url": "https://monsaiapp.com/404/",
              "description": "The page you are looking for could not be found. Explore Monsai for personal growth, wellness, and productivity."
            }
          `}
        </script>
      </Helmet>

      <Error />
      <FooterSection />
    </PageWrapper>
  )
}
